<template>
    <b-modal id="modal" size="lg" :title="data.name"
             @close="onReset()"
             no-close-on-esc
             no-close-on-backdrop
    >
        <b-form id="form1" @reset="onReset" @submit.prevent="onSubmit" v-if="data.id" autocomplete="off">
            
            <b-row v-if="role == 'admin'">
                <div class="col-12 form-group">
                  <label>{{$t('meetings.tableName')}}</label>
                  <b-form-input v-model="data.name" type="text" ></b-form-input>
                </div>
                <div class="col-12 form-group">
                  <label >{{$t('meetings.tableDateCol')}} </label>
                  <b-form-datepicker v-model="data.date.date" class="mb-2"></b-form-datepicker>
                </div>
            </b-row>

            <div class="row">
              <div class="form-group col-12 mt-3">
                <label>{{$t('projects.attachments')}}</label>
                <local-file-manager-meetings class="mb-4"
                                             :read-only="((role !== 'admin' ) ? true : false)"
                                             :id-foreign-table-row="data.id">

                </local-file-manager-meetings>
              </div>
            </div>

          <hr v-if="role == 'admin'">

            <b-form-group v-if="role == 'admin'" class="text-right">
                <b-button variant="danger"  @click="deleteItem(data.id)">Smazat
                    <b-icon icon="trash-fill" ></b-icon>
                </b-button>
            </b-form-group>
        </b-form>
        <template v-slot:modal-footer>
            <div class="w-100">
                <b-button type="reset" class="float-left" form="form1" variant="outline-secondary">
                    Zavřít
                </b-button>
                <b-button type="submit" class="float-right" form="form1" variant="primary">
                    Uložit
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>

import LocalFileManagerMeetings from "../../components/local-file-manager-meetings.vue";

export default {
    name: "meetings-edit",
  components: {LocalFileManagerMeetings},
    data() {
        return {
            id: parseInt(this.$route.params.id),
            data: {}
        }
    },
    methods: {
        async loadData() {
            try {
                const response = await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/meetings/list.php',
                    headers: { 'content-type': 'application/x-www-form-urlencoded' },
                    params: {'id': this.id },
                    method: 'GET'
                });
                
                this.data = response.data[0];
                
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
            
        },
        async onSubmit() {
            try {
                await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/meetings/update-item.php',
                    headers: { 'content-type': 'application/x-www-form-urlencoded' },
                    data: this.data,
                    method: 'PUT'
                });
                
                this.$emit('loadData');
                this.$bvModal.hide('modal');
                this.$router.go(-1);
                this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.success'));
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
            
        },
        async deleteItem(id) {
            try {
                if (confirm(this.$t('projects.deleteItem'))) {
                    await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/meetings/remove-item.php',
                        headers: {'content-type': 'application/x-www-form-urlencoded'},
                        data: {'id': id},
                        method: 'DELETE'
                    });
                    this.$emit('loadData');
                    this.$bvModal.hide('modal');
                    this.$router.go(-1);
                    this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.deleted'));
                }
                
            } catch (error) {
                this.loadData();
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
            
        },
        onReset () {
            this.$emit('loadData');
            this.$router.go(-1);
        }
        
    },
    computed: {
      role: function () {
        try {
          return this.$store.getters.user.permission;
        } catch (err) {
          return false
        }
      },
    },
    mounted() {
        this.$bvModal.show('modal');
        this.loadData();
    },
    created() {
    
    }
}
</script>

<style scoped lang="scss">

</style>
